//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueCountdown from '@chenfengyuan/vue-countdown';
import {mapActions, mapState, mapMutations} from 'vuex';
import $ from 'jquery';
import * as moment from 'moment';

import notification from "@/notification/notification";
import util from '@/utils';
import RestrictedContent from '@/components/errorBlocks/RestrictedContent';
import ArrowLeft from '@/components/icons/ArrowLeft';
import IspringTest from './test/IspringTest';
import CustomTest from './test/CustomTest';
import record from '../record/record';
import api from '../api/api';
import config from '../../app.config';
import LessonHeader from '../headers/LessonHeader';

export default {
  name: 'Test',
  metaInfo() {
    return {
      title: this.title,
    }
  },
  props: {
    msg: String
  },
  data() {
    return {
      sessionStarted: false,
      testStarted: false,
      cameraStarted: false,
      isScreenshot: false,
      isDelete: false,
      agreeVideo: false,
      familiarized: false,
      config,
      isVideoError: false,
      period: null,
      periodTimeout: null,
      title: this.$i18n.t('profile.title'),
      sessionId: null,
      isPhotoPopup: false,
      isPhotoSubjected: true,
      testError: null,
      isInitTest: false,
      restrictedError: '',
      stopwatch: 0,
      delay: 0,
      isSessionTimeout: false,
      visibilityStateHandler: null,
    }
  },
  async created() {
    this.visibilityStateHandler = util.handleVisibilityState(this.togglePing.bind(this));
    
    this.isInitTest = false;
    this.restrictedError = '';
    if ((!(this.$route.params.courseId in this.course) || !this.tests) && !this.paperId) {
      const params = this.$route.params.type
        ? { extNeedId: this.$route.params.needId } 
        : { needId: this.$route.params.needId };
        
      const response = this.getCourse(params);
      await response.then(() => {
        this.isPhotoPopup = this.test ? (this.course.RECORD_VIDEO || this.course.SHOOT_PHOTO) : false;

        $(document).ready(() => {
          if (this.isPhotoPopup) {
            $('#modalMain').modal('show');
          } else {
            this.startTest(null, true);
          }
        });
      })
    }

    this.setDuration();

    if (this.paperId) {
      this.startTest(null, true);
    }

    this.isVideoError = false;
  },
  destroyed() {
    if (this.visibilityStateHandler)
      this.visibilityStateHandler();
  },
  computed: {
    ...mapState([
      'course',
      'token',
      'testStartTime',
      'pingTime',
      'testDuration',
      'trainingTest',
      'courseRestricted',
    ]),

    error() {
      if (this.courseError) {
        return this.courseError;
      }

      if (this.testError) {
        return this.testError;
      }

      return null;
    },

    isTestButton() {
      return (
          !this.isVideoError && this.isAgree && ((this.course.SHOOT_PHOTO && this.isDelete) || (!this.course.SHOOT_PHOTO && this.cameraStarted))
          || this.isVideoError && this.familiarized
      );
    },
    isAgree() {
      return ((this.test && this.course.RECORD_VIDEO && this.agreeVideo) || (!this.test || !this.course.RECORD_VIDEO)) && this.familiarized;
    },
    id() {
      return this.$route.params.id;
    },
    courseId() {
      return Number(this.$route.params.courseId);
    },
    paperId() {
      return this.$route.params.paperId;
    },
    test() {
      if (this.paperId && this.trainingTest && this.trainingTest.NAME) {
        return this.trainingTest;
      }

      if (
          !this.course.GROUPS
          && (!this.course.FINAL_TESTS || !this.course.FINAL_TESTS.length)
          && (this.course.ROOT && this.course.ROOT.MATERIALS)
      ) {
        return null;
      }

      if (this.course.FINAL_TESTS) {
        for (let finalTest of this.course.FINAL_TESTS) {
          if (finalTest.ID == this.id) {
            return finalTest;
          }
        }
      }

      if (this.course.GROUPS) {
        for (let group of this.course.GROUPS) {
          for (let material of group.MATERIALS) {
            if (material.ID == this.id) {
              return material;
            }
          }
        }
      }

      if (this.course.ROOT && this.course.ROOT.MATERIALS) {
        for (let material of this.course.ROOT.MATERIALS) {
          if (material.ID == this.id) {
            return material;
          }
        }
      }

      return null;
    },
    testType() {
      if (!this.test) {
        return '';
      }

      return this.test.MATERIAL_TYPE;
    },
    isIspring() {
      return this.testType === 'ispring';
    },
    isCustom() {
      return this.testType === 'custom';
    }
  },
  methods: {
    setDuration() {
      if (this.test && this.test.DURATION) {
        this.setTestDuration(util.getCountdownTime(this.test.DURATION));
      }
    },
    isFinal(id) {
      return id === 'final';
    },
    padStart(value, length, chars) {
      let str = value.toString();
      while (str.length < length) str = chars + str;
      return str;
    },
    isTest(id) {
      return (id in this.tests) && this.tests[id].src;
    },
    stopTest() {
      this.testStarted = false;
      record.stopVideo(this.sessionId, this.id);
      this.stopPing();
    },
    play() {
      try {
        this.setIsStartVideo(true);
        record.startVideo(this.sessionId, this.id);
      } catch (e) {
        //console.log(e.message);
      }
    },
    screenshot() {
      try {
        record.takeScreenShot();
        this.isScreenshot = true;
        this.showDelete()
      } catch (e) {
        this.isVideoError = true;
      }
    },
    savePhoto() {
      if (!this.isVideoError) {
        record.saveImage(this.sessionId, this.id);
      }
    },
    async startTest(e, force = false) {
      this.restrictedError = ''

      if (!this.isTestButton && !force) {
        return;
      }

      let session = this.paperId
          ? await api.startTrialTestSession(this.id)
          : await api.startTestSession(this.$route.params.courseId, this.id, this.$route.params.type ? { extNeedId: this.$route.params.needId} : { needId: this.$route.params.needId});

      if (session && session.data && session.data.error && session.data.errorStr) {
        if (session.data.error === 401) {
          this.$router.push({
            name: 'course',
            params: {
              id: this.$router.currentRoute.params.courseId
            }
          });
        } else if (session.data.error === 403){
          this.restrictedError = session.data.errorStr
        } else {
          this.testError = session.data.errorStr
          return
        }
      }

      if (session.data && session.data.result && session.data.result.id) {
        this.sessionId = session.data.result.id

        this.isInitTest = true
        this.testStarted = true
        
        this.setTestStartTime(new Date())

        try {
          $('#modalMain').modal('hide')

          if (this.test && this.course.SHOOT_PHOTO) {
            this.savePhoto()
          }

          if (this.test && this.course.RECORD_VIDEO) {
            this.play()
          }

        } catch (e) {
          this.isVideoError = true
        }

        this.startPing()
      }
    },
    initCamera() {
      try {
        let init = record.init(this.$refs.video, this.$refs.video_mini, this.$refs.photo)

        init.then((res) => {
          if (res) {
            this.cameraStarted = true
          } else {
            throw new Error('error')
          }
        }).catch(() => {
          this.isVideoError = true
        });
      } catch (e) {
        this.isVideoError = true
      }

    },
    reInitCamera() {
      this.isVideoError = false;
      this.initCamera();
    },
    rescreenshot() {
      this.cameraStarted = true;
      this.isScreenshot = false;
      this.isDelete = false;
    },
    showDelete() {
      this.isDelete = true;
    },
    endTimer() {
      if (this.testStarted) {
        $('#modalTimeOut').modal('show');
        record.stopVideo(this.sessionId, this.id);
        this.stopPing();
      }
    },
    backToCourse() {
      if (this.period) {
        api.testTimedOut(this.sessionId, this.id);
      }
      this.stopPing();
      setTimeout(() => {
        $('#modalTimeOut').modal('hide');

        if (this.paperId) {
          this.$router.push({
            name: 'training',
          });
        } else {
          this.$router.back();
        }

      }, 500);
    },
    backToTrainings() {
      if (this.period) {
        api.testTimedOut(this.sessionId, this.id);
      }
      this.stopPing();
      setTimeout(() => {
        $('#modalTimeOut').modal('hide');
          this.$router.back();
      }, 500);
    },
    startPing() {
      this.stopwatch = moment();
      if (this.delay) {
        this.periodTimeout = setTimeout(() => {
            this.ping();
            this.period = setInterval(this.ping, this.pingTime * 1000);
          }, this.delay);
      } else {
        this.period = setInterval(this.ping, this.pingTime * 1000);
      }
    },
    stopPing() {
      clearInterval(this.period);
      this.period = null;
      if (this.periodTimeout) {
        clearTimeout(this.periodTimeout);
        this.periodTimeout = null;
      }
    },
    togglePing(isHidden) {
      if (!this.testStarted) return;
      if (isHidden) {
        this.stopPing();
        this.pauseCountdown();
        this.delay = (this.delay ? this.delay : this.pingTime * 1000) - moment().diff(this.stopwatch);
      } else {
        this.startPing();
        this.resumeCountdown();
      }
    },
    pauseCountdown() {
      if (this.$refs && this.$refs.countdown)
        this.$refs.countdown.abort();
    },
    resumeCountdown() {
      if (this.$refs && this.$refs.countdown)
        setTimeout(() => this.$refs.countdown.start())
    },
    ping() {
      if (this.delay) this.delay = 0;
      this.stopwatch = moment();
      
      if (!this.testStartTime) {
        this.stopPing();
        return;
      }
      const session = api.pingTest(
        this.sessionId, 
        this.$route.params.courseId, 
        this.$route.params.id, 
        (this.$route.params.type ? { extNeedId: this.$route.params.needId} : { needId: this.$route.params.needId } )
      );
      session.then(response => {
        if (response.data && response.data.result && response.data.result.success) {
          this.isSessionTimeout = false;
        } else {
          this.isSessionTimeout = true;
          this.showSessionTimeoutAlert();
        }
      })

    },
    showSessionTimeoutAlert() {
      this.stopPing();
      this.pauseCountdown();
      if (this.visibilityStateHandler)
        this.visibilityStateHandler();
      notification.alert(
        'Сессия истекла, перезагрузите страницу.', 
        { 
          name: 'Перезагрузить', 
          cb: () => {
            location.reload() 
          }, 
          className: 'btn btn-danger' 
        },
        {
          onShow: () => {
            $('.noty_buttons')[0].classList.add('justify-content-md-center')
          },
        }
      );
    },
    ...mapActions([
      'getCourse'
    ]),
    ...mapMutations([
      'setIsStartVideo',
      'setTestStartTime',
      'setTestDuration'
    ]),
  },
  components: {
    RestrictedContent,
    CustomTest,
    IspringTest,
    LessonHeader,
    VueCountdown,
    ArrowLeft
  }
}
